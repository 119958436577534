<template>
  <div>
    <section>
      <div class="content-wrap">
        <div
          class="section nomargin share-description-section"
          v-if="fundData_x"
        >
          <div class="container" id="donationContainer">
            <div class="donation-payment-method">
              <h3 class="block-title">Donate To {{ campaign_name }}</h3>
              <div v-if="visa_success === true">
                <p>Success!!</p>
                <p>
                  {{ this.visa_success_message }}
                </p>
              </div>
              <div v-else>
                <PaymentTab
                  :mpesaCheckoutAmout="mpesaCheckoutAmout"
                  :primer="primer"
                  :primer_b="primer_b"
                  :primer_c="primer_c"
                  :paybill="paybill"
                  :accountNumber="accountNumber"
                  :synonym="synonym"
                  :businessName="businessName"
                  :campaign_name="campaign_name"
                  :campaign_id="campaign_id"
                  :champion_code="champion_code"
                  :currencyConfig="currencyConfig"
                  :step="step"
                  :activePayTab="activePayTab"
                  :isFlutterwave="isFlutterwave"
                  :myCurrency="myCurrency"
                  :myCountryCode="myCountryCode"
                  :myIntPrefix="myIntPrefix"
                  :marketOption="marketOption"
                  :mclIFrame="mclIFrame"
                  :alt_country_code="alt_country_code"
                />
              </div>
            </div>
            <div class="center">
              <em>
                Powered by
                <a :href="this.baseURL" target="_blank">M-Changa Africa</a>
              </em>
              <br />
              <a :href="this.baseURL + '/terms-of-use'" target="_blank"
                >Terms</a
              >
              &nbsp; | &nbsp;
              <a :href="this.baseURL + '/privacy-policy'" target="_blank"
                >Privacy</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { lazyLoadComponent } from '../utils/lazy-load-components';
import loader from '../components/loader.vue';
import PaymentTab from '../components/PaymentTab.vue';

export default {
  beforeCreate() {},
  components: {
    loader,
    PaymentTab,
    FooterV: lazyLoadComponent({
      componentLoader: () => import('../components/Footer/index.vue'),
      loadingComponent: loader,
    }),
  },
  data() {
    return {
      visa_success: false,
      visa_success_message: '',
      currentYear: new Date().getFullYear(),
      fundData: {},
      duration: 2,
      mpesaCheckoutAmout: 200,
      primer: 200,
      primer_b: 1000,
      primer_c: 5000,
      campaign_id: 0,
      fundData_x: {},
      featureImage: '',
      story: '',
      videoSource: '',
      campaign_name: '',
      imagelist: [],
      imageCount: 0,
      paybill: this.mchangaPAYBILL,
      businessName: this.airtelBusinessName,
      accountNumber: this.$route.params.id,
      synonym: '',
      daysleft: 0,
      champion_code: false,
      champion_fundraiser: false,
      champList: {},
      champion_name: '',
      champion_amount: 0,
      champion_donors: 0,
      currencyConfig: {},
      uk_aid: false,
      cdf_partner: false,
      local_amount: 0,
      local_ex_rate: 1,
      local_target: 1,
      display_currency: '',
      my_url: '',
      leaderBoard: 0,
      topDonors: [],
      top_index: 0,
      leaderBoardShow: false,
      pageConfig: {
        identifier: 0,
        url: window.location.href,
      },
      step: 5,
      activePayTab: false,
      isFlutterwave: 'no',
      marketOption: 'zero', // default ke market
      openTab: 1,
      facebookID: process.env.VUE_APP_FACEBOOK_ID,
      fbURL: 'https://www.facebook.com/sharer/sharer.php?u=',
      twURL: 'https://twitter.com/intent/tweet?text=',
      waURL: 'https://api.whatsapp.com/send?text=',
      alt_country_code: '',
      myCurrency: '',
      myCountryCode: '',
      myIntPrefix: '',
      mclIFrame: true,
      partner_logo: '',
      country_logo: '',
      active_flag: 0,
      autoplay: 2000,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        autoplay: 2000,
      },
      breakpoints: {
        700: {
          itemsToShow: 1,
          snapAlign: 'center',
          autoplay: 3000,
        },
        1024: {
          itemsToShow: 1,
          snapAlign: 'start',
          autoplay: 3000,
        },
      },
    };
  },
  methods: {
    topDonorUpdate() {
      setTimeout(() => {
        this.top_index += 1;
        if (this.top_index >= this.topDonors.length) {
          this.top_index = 0;
        }
        this.topDonorUpdate();
      }, 5000);
    },
  },

  async created() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ transactionID: this.$route.params.id, iFrame: 1 }),
    };
    const response = await fetch(
      `${process.env.VUE_APP_ROOT_API}/service/fundraiser/share-page`,
      requestOptions,
    )
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          console.log('data');
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        const primerAmount = Math.ceil((1.2 * data.primerAmount) / 100) * 100;
        this.fundData = data;
        let fund_country_code = data.country_code.toUpperCase();
        this.myIntPrefix = this.local_country_codes[fund_country_code];
        this.myCurrency = data.currency.toUpperCase();
        this.myCountryCode = data.country_code.toUpperCase();
        this.alt_country_code = data.alt_country_code;
        this.primer = primerAmount;
        this.campaign_id = data.campaign_id;
        this.pageConfig.identifier = data.campaign_id;
        this.campaign_name = data.campaign_name;
        this.mpesaCheckoutAmout = primerAmount;
        this.currencyConfig = data.currencyConfig;
        this.display_currency = this.fundData.currency;
        this.step = this.fundData.step;
        this.fbURL += encodeURIComponent(
          `${window.location.href}&t=${data.campaign_name} | M-Changa`,
        );
        this.twURL += encodeURIComponent(
          `${data.campaign_name} | M-Changa ${window.location.href}`,
        );
        this.waURL += encodeURIComponent(
          `${data.campaign_name} | M-Changa ${window.location.href}`,
        );
        if (
          this.available_countries.includes(data.country_code.toUpperCase())
        ) {
          this.active_flag = 1;
        }
        if (data.status == 1 && data.blocked == 0 && data.suspended == 0) {
          this.activePayTab = true;
          if (data.currency.toLowerCase() == 'kes') {
            this.isFlutterwave = false;
          } else if (this.flutter_currency_codes.includes(this.myCurrency)) {
            this.isFlutterwave = true;
            this.openTab = 5;
            this.marketOption = 'one'; // flutterwave - GH,UG
          } else {
            this.isFlutterwave = false;
          }
          // special MOMO only fundraisers
          if (data.currencyConfig.credit_card == 0) {
            this.marketOption = 'momo';
          }
          // special card only fundraisers
          if (data.currencyConfig.mobile_money == '0') {
            this.openTab = 6;
            this.marketOption = 'card';
          }
        }
        if (data.currencyConfig.currency_code != this.fundData.currency) {
          this.local_ex_rate = data.ex_rates.val;
          var my_local_amount = (data.conv_amount / this.local_ex_rate).toFixed(
            2,
          );
          var my_local_target = (data.target / this.local_ex_rate).toFixed(2);
          this.local_target = my_local_target
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.local_amount = my_local_amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.display_currency = data.ex_rates.display_currency;
          this.marketOption = 'zero'; // default to KE options
        }
        // senegal logo temp fix - wait for cloudinary access
        const sen_list = [
          58238, 58235, 58102, 58049, 57913, 57677, 57609, 57517, 57277, 58246,
          58433, 58488, 58633, 58756, 58749, 58798, 58825, 58928,
        ];
        if (sen_list.includes(data.transaction_id)) {
          this.partner_logo = 'senegal_1';
        }
        // Rotary logo temp fix - wait for cloudinary access
        const rot_list = [57216];
        if (rot_list.includes(data.transaction_id)) {
          this.partner_logo = 'rotary_1';
        }
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error('There was an error!', error);
      });

    //fetch other share page data - datax
    const requestOptions_x = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ campaign_id: this.campaign_id }),
    };
    const response_x = await fetch(
      `${process.env.VUE_APP_ROOT_API}/service/fundraiser/share-page-details`,
      requestOptions_x,
    )
      .then(async (response_x) => {
        const data_x = await response_x.json();

        // check for error response
        if (!response_x.ok) {
          // get error message from body or default to response status
          const error = (data_x && data_x.message) || response_x.status;
          return Promise.reject(error);
        }
        this.fundData_x = data_x.shareData;
        this.story = this.fundData_x.message;
        if (this.story == '') {
          this.story = this.fundData_x.custom_sentence;
        }
        this.champion_fundraiser = this.fundData_x.champion_status;
        this.leaderBoard = this.fundData_x.auto_rank_prompts;
        this.videoSource = '';
        const video_str = this.fundData_x.video_frame;
        if (
          video_str != '' &&
          video_str.includes('iframe') &&
          video_str.includes('src')
        ) {
          var parser = new DOMParser();
          var parsedIframe = parser.parseFromString(
            video_str.replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
            'text/html',
          );
          let iFrame = parsedIframe.getElementsByTagName('iframe');
          this.videoSource = iFrame[0].src;
        }

        this.synonym = this.fundData_x.synonym;
        this.imagelist = data_x.images;
        const featImageID = Math.floor(Math.random() * this.imagelist.length);
        this.featureImage = this.imagelist[featImageID].src;
        this.imageCount = this.imagelist.length;
        this.daysleft = this.fundData_x.duration - this.fundData_x.fund_age;
        if (this.daysleft < 0) {
          this.daysleft = 0;
        }
        if (this.leaderBoard) {
          this.topDonors = data_x.topDonors;
        }
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error('There was an error!', error);
      });

    // stripe session check
    const session_id = this.$route.query.session_id;
    const myAccount = this.$route.params.id;
    const myBase = this.baseURL;
    if (session_id !== undefined) {
      this.visa_success = true;
      this.visa_success_message = 'Please wait....';
      const stripe_session = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ session_id: session_id }),
      };
      const stripe_resp = await fetch(
        `${process.env.VUE_APP_ROOT_API}/service/donation/stripe-session-status`,
        stripe_session,
      ).then(async (stripe_resp) => {
        const stripe_trans = await stripe_resp.json();
        if (stripe_trans.status == 'complete') {
          this.visa_success_message =
            'M-CHANGA: Thank you ' +
            stripe_trans.session.name +
            ' for your donation.';
        } else {
          this.visa_success_message = 'Payment processing....';
        }
      });
      setTimeout(function () {
        window.location.replace(myBase + '/mcl-donation/' + myAccount);
      }, 5000);
    }
  },
};
</script>
<style>
body {
  background: #fff !important;
}

a {
  color: #000;
}

.modal-header h3 {
  font-size: 14px;
  letter-spacing: 0;
}

li {
  font-size: small;
}

ul {
  margin-bottom: 10px;
}

#my_centered_buttons {
  display: flex;
  justify-content: center;
}

.sm-text {
  font-weight: lighter;
  font-size: x-small;
  color: #555;
}

.payment-text span {
  display: block;
  flex-direction: column;
  letter-spacing: 2px !important;
  line-height: 0.8em;
  font-weight: 400;
  margin: 0 0 5px 0;
  padding: 10px;
}

/*CSS for Pausing Scroller */

/*responsive */
@media (max-width: 675px) {
  .section,
  .content-wrap {
    padding: 0;
  }

  .donation-payment-method {
    margin-top: 10px;
  }

  #donationContainer {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
</style>
